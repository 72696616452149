.integrity-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 0 20px;

    .integrity {
        margin-bottom: 20px;
        border-radius: 5px;
        border: 1px solid;
        padding: 20px;
        flex: 0 0 300px;

        &.integrity-unverified {
            background: #FFEAEF;
            color: #9F1239;
        }

        &.integrity-modified {
            background: #FFF8E5;
            color: #AD810A;
        }

        .integrity-name {
            display: block;
            font-weight: bold;
            font-size: 18px;
            margin-bottom: 15px;
        }

        a {
            margin-top: 20px;
        }
    }
}