.login-wrapper {
    .content {
        background: none;
        box-shadow: none;
    }

    .logo {
        .logo();
        font-size: 0;
        color: transparent;
        width: 150px;
        height: 50px;
    }
}