@import './layout.less';
@import './actions.less';
@import './servers.less';
@import './hours.less';
@import './backups.less';
@import './login.less';
@import './integrity.less';
@import './prognosis.less';
@import './notifications.less';
@import './website.less';

:root {
    --money-negative: darkred;
}

i.fa, i.far, i.fas {
    font-family: Font Awesome\ 6 Pro !important;
}

.date-selector {
    flex: 0 0 500px;
    display: inline-flex;
    border: 1px solid var(--outline-color);
    background: #fff;
    border-radius: 5px;
    flex-wrap: wrap;
    justify-content: stretch;

    &.only-years {
        flex-basis: 105px;
    }

    .date-selector-years {
        display: flex;
        flex: 0 0 105px;
        align-items: center;
        justify-content: center;

        a {
            margin: 5px;
            padding: 5px;
            border-radius: 3px;
        }

        span {
            padding: 10px 0;
            font-size: 20px;
            font-weight: bold;
        }
    }

    .date-selector-all-year {

        a {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;

            padding: 10px;
            width: 90px;
            height: 100%;
        }
    }

    a {
        color: inherit;

        &:hover {
            background: #f8f8f8;
        }

        &.current {
            font-weight: bold;
            color: var(--dondr-primary);
        }
    }

    .date-selector-all-year,
    .date-selector-months {
        border-left: 1px solid var(--outline-color);
    }

    @media screen and (max-width: 600px) {

        .date-selector-all-year,
        .date-selector-months {
            border-left: none;
        }

        .date-selector-months {
            border-top: 1px solid var(--outline-color);
        }

    }

    .date-selector-months {
        display: flex;
        flex-wrap: wrap;
        width: 301px;

        a {
            flex: 0 0 50px;
            text-align: center;
            padding: 5px 10px;
        }
    }
}

.money-cell {
    text-align: right;
}

.money {
    display: inline-flex;
    width: 100px;
    justify-content: space-between;

    &::before {
        content: '€';
        display: inline-block;
        font-weight: normal;
        margin-right: 30px;
    }
}

.money.negative,
.money-cell.negative .money {
    color: var(--money-negative);
}

.input-group-text {
    height: unset;
}

.section {
    background: #fff;
    border-radius: 5px;
    border: 1px solid #E2E8F0;
    margin-bottom: 20px;
    color: var(--bs-body-color);

    .section-title {
        padding: 10px 15px;
        font-weight: bold;
        border-bottom: 1px solid #E2E8F0;

        a {
            color: var(--dondr-primary);
        }
    }

    .section-content {
        padding: 10px;

        :first-child {
            margin-top: 0;
        }

        :last-child {
            margin-bottom: 0;
        }
    }

    table {
        margin-bottom: 0;

        td:first-child,
        th:first-child {
            padding-left: 15px;
        }

        td:last-child,
        th:last-child {
            padding-right: 15px;
        }

        &> :last-child> :last-child {

            th,
            td {
                border-bottom: none;
            }
        }
    }
}

.scrollable-table-wrapper {
    overflow-x: auto;
    width: 100%;
}

.planner-wrapper-inner {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    max-width: 83vw;
}

.planner {
    border-collapse: collapse;

    td+td {
        min-width: 25px;
        max-width: 25px;
    }

    td.weekend {
        background: #f0c9c9;
    }

    .planner-project {
        display: block;
        padding: 3px 5px;
        border-radius: 3px;
        background: #ffffff;
        border: 1px solid #E2E8F0;
        overflow: hidden;
        text-overflow: ellipsis;
        user-select: none;
    }

    .planner-draggable {
        display: block;
        height: 29px;
    }
}

.cell-popup {
    display: none;
    position: relative;
}

&:hover>.cell-popup {
    display: block;
    position: absolute;
    z-index: 1000;
    background: #222;
    color: #fff;
    border-radius: 5px;
    padding: 10px;
    min-width: 200px;
    // max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    line-height: 1.5;
    text-align: left;

    ul {
        margin: 0;
        padding-left: 15px;
    }
}

.project-progress {
    border-radius: 4px;
    height: 12px;
    width: 100px;
    background: #fff;
    display: inline-block;
    position: relative;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
    color: #444;
    font-size: 11px;
    line-height: 12px;
    text-align: right;
    padding-right: 5px;

    .project-progress-done,
    .project-progress-checked {
        overflow: hidden;
        position: absolute;
        border-radius: 4px;
        height: 12px;
        left: 0;
        top: 0;
        color: #fff;
        font-size: 11px;
        line-height: 12px;
        text-align: right;
        padding-right: 5px;
        padding-left: 5px;
        min-width: 15px;
    }

    .project-progress-done {
        background: orange;
    }

    .project-progress-checked {
        background: green;
    }
}

.form-search {
    background-color: var(--button-bg);
    border: 0;
    padding: 5px;
    box-shadow: var(--button-shadow);
    border-radius: 3px;
    border: 1px solid var(--button-border);
    color: var(--button-color);
}