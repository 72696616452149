.navbar-notifications {
    position: relative;

    .navbar-notifications-icon {
        position: relative;
        margin: 0 20px;
        padding: 6px 10px;
        border-radius: 3px;
        cursor: pointer;

        &:hover {
            background: var(--dropdown-link-hover-bg);
            box-shadow: 0 0 0 1px var(--content-top-border-color);
        }

        .navbar-notifications-badge {
            position: absolute;
            top: -3px;
            right: 0px;
            background: #E5006A;
            color: #fff;
            padding: 1px 5px;
            display: inline-block;
            border-radius: 3px;
            font-size: 10px;
        }
    }

    .navbar-notifications-list {
        position: absolute;
        display: none;
        background: #fff;
        width: 300px;
        border: 1px solid var(--content-top-border-color);
        border-radius: 3px;
        right: 20px;
        margin-top: 10px;
        z-index: 10000;

        .navbar-notification {
            padding: 10px 20px;

            &.navbar-notification-unread {
                box-shadow: inset 2px 0 0 0 var(--dondr-primary);
            }

            .navbar-notification-title {
                font-weight: bold;
            }

            .navbar-notification-message {
                margin-top: 5px;
                font-size: 0.9em;
            }

            .navbar-notification-time {
                font-size: 0.9em;
                color: #999;
                margin-top: 5px;
            }

            & + .navbar-notification {
                border-top: 1px solid var(--content-top-border-color);
            }
        }
    }

    &.visible .navbar-notifications-list {
        display: block;
    }
}