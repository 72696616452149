.server-overview {
    display: flex;
    flex-wrap: wrap;
    gap: 0 20px;
    align-items: flex-start;

    .server {
        max-width: 100%;
        flex: 0 0 580px;
        border: 1px solid var(--outline-color);
        padding: 25px 30px;
        border-radius: 5px;
        margin-bottom: 20px;
        background: #fff;
        position: relative;

        .server-load {
            position: absolute;
            bottom: 0;
            left: 0;
            height: 100px;
            width: 100%;
        }

        .server-header {
            display: flex;
            cursor: pointer;
            position: relative;

            .server-icon {
                border: 1px solid var(--outline-color);
                padding: 15px;
                width: 50px;
                height: 50px;
                border-radius: 3px;
                font-size: 20px;
                color: inherit;
                transition: 0.25s ease border-color;

                i {
                    display: block;
                }

                &[href]:hover {
                    border-color: var(--dondr-primary);
                }
            }

            span.server-icon {
                cursor: not-allowed;
            }

            .server-header-info {
                margin-left: 20px;

                .server-name {
                    color: var(--dondr-primary);
                    font-size: 18px;
                    font-weight: bold;
                }

                .server-times {
                    margin-left: 10px;
                }

                .server-header-details {
                    span {
                        display: inline-block;
                        margin-right: 20px;

                        i {
                            margin-right: 5px;
                        }
                    }
                }
            }

            .server-header-expand {
                margin-left: auto;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #666;
                i {
                    transition: 0.5s ease transform;
                }
            }
        }

        &.expanded .server-header-expand i {
            transform: rotate(180deg);
        }

        .server-expand {
            position: relative;
            overflow: hidden;
            max-height: 0;
            transition: 0.5s ease max-height;
        }

        &.expanded .server-expand {
            max-height: 600px;
        }

        .server-detail {
            margin-top: 20px;
            margin-bottom: 20px;
            display: flex;
            align-items: baseline;

            i {
                flex: 0 0 30px;
            }
        }

        .server-storage-status {
            &.server-storage-status-critical {
                font-weight: bold;
                color: #DE2626;
            }
            &.server-storage-status-warning {
                font-weight: bold;
                color: #E59E00;
            }
        }

        .server-detail .server-storage-status {
            &.server-storage-status-good {
                font-weight: bold;
                color: #2DBA27;
            }
        }
    }
}