.full-accounts {
    border-radius: 5px;
    border: 1px solid;
    background: #FFEAEF;
    color: #9F1239;
    display: flex;
    margin-bottom: 20px;
    padding: 0 10px;

    .full-account {
        padding: 10px;

        .full-account-name {
            font-weight: bold;
        }
    }
}

.actions-assign {
    border: 1px solid var(--outline-color);
    border-radius: 5px;
    padding: 5px;
    flex-direction: column;
    width: 200px;
    background: #ffffff;
    position: absolute;
    display: none;
    z-index: 1000;

    &.visible {
        display: flex;
    }

    a {
        padding: 5px;
        border-radius: 3px;
        color: var(--dondr-primary);
        display: none;

        &.visible {
            display: block;
        }

        &:hover {
            background-color: #f8f8f8;
        }
    }
}

.actions-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 0 20px;

    .actions-spacer {
        flex: 1 0 300px;
    }

    .actions-action {
        flex: 1 0 300px;
        background: #fff;
        border: 1px solid var(--outline-color);
        padding: 20px;
        border-radius: 5px;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        min-height: 100px;
        position: relative;
        word-break: break-word;

        &.contains-current-user {
            box-shadow: inset 0 0 0 2px var(--dondr-primary);
        }

        .actions-header {
            color: var(--dondr-primary);
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            justify-content: space-between;

            .actions-drag {
                display: flex;
                align-items: center;

                i {
                    color: #999;
                    margin-right: 10px;
                }
            }

            .actions-bar {
                display: block;
                width: 200px;
                border-top: 1px solid var(--dondr-primary);
            }

            .actions-delete {
                text-decoration: none;
                color: var(--dondr-primary);
            }

            .actions-save {
                appearance: none;
                color: var(--dondr-primary);
                cursor: pointer;
                background: none;
                border: none;
                padding: 0;
            }
        }

        .actions-input {
            padding: 0;
            border: none;
        }

        .actions-title {
            font-weight: bold;
            margin-right: 35px;
            margin-bottom: 5px;
        }

        .actions-content {
            margin-bottom: 1rem;
            max-height: 200px;
            overflow: auto;
        }

        .actions-details {
            margin-top: auto;
            font-size: 0.9em;
            color: #555;

            .actions-assignees {
                margin-bottom: 10px;
                display: flex;
            }

            .actions-assignees-user {
                color: var(--dondr-primary);
                margin-right: 10px;

                &:hover {
                    opacity: 0.5;
                }
            }

            .actions-assignees-add {
                display: none;
                color: var(--dondr-primary);
            }

            .actions-author {
                font-weight: bold;
                color: var(--dondr-primary);
            }
        }

        &:hover .actions-assignees-add {
            display: block;
        }
    }
}