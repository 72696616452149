
.update-available {
    color: #dc3545;
}

.item-status {
    display: inline-block;
    width: 25px;
    height: 25px;
    text-align: center;
    font-size: 13px;
    line-height: 25px;
    border-radius: 50%;
    background-color: #6c757d;
    color: #fff;
}
a.item-status:hover {
    color: #fff;
    text-decoration: none;
}

.item-status-ok {
    background-color:rgb(85, 157, 215);
}
.item-status-connectionerror {
    background-color: #dc3545;
}
.item-status-autherror {
    background-color: #dc3545;
}
.item-status-protocolerror {
    background-color:rgb(234, 153, 4);
}
tr.extra-url:not(.has-problem):not(.visible) {
    display: none;
}
td.test-error {
    background: pink !important;
    color: darkred;
}
td.test-warning {
    color: #8A5900;
}
td.test-column {
    width: max-content;
}
td.test-column i {
    width: 15px;
}
td.test-column i ~ i {
    margin-left: 5px;
}