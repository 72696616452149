@import url("https://use.typekit.net/dhz7jko.css");

:root,
.ea-dark-scheme {
    --dondr-primary: #dd035f;
    --outline-color: #E8E7ED;

    --sidebar-menu-badge-bg: var(--dondr-primary);
    --sidebar-menu-badge-color: #fff;
    --sidebar-menu-badge-active-bg: #fff;
    --sidebar-menu-badge-active-color: var(--dondr-primary);
    --bs-border-radius-pill: 3px;
    --dondr-black: #292929;
    --sidebar-menu-icon-color: var(--dondr-black);
    /* ea */
    --body-max-width: 100%;
    --font-size-base: 14px;
    --border-radius: 5px;
    --button-primary-bg: var(--dondr-primary);
    --pagination-active-bg: var(--dondr-primary);
    --sidebar-menu-active-item-color: #ffffff;
    --sidebar-menu-active-item-bg: var(--dondr-primary);
    --resize-handler-hover-bg: var(--dondr-primary);
    --button-shadow: none;
    --font-family-base: acumin-pro, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --page-login-bg: #F8F7FA;
}

.btn {
    --bs-btn-padding-x: 13px;
    --bs-btn-padding-y: 5px;
}

#main-menu .menu .menu-item-badge {
    top: 0;
}

.content-top {
    background: #ffffff;

    &::before, &::after {
        position: absolute;
        left: -1px;
        height: 20px;
        width: 1px;
        background: #fff;
        content: '';
    }

    &::before { top: 0; }
    &::after { bottom: 0; }
}

.main-header .navbar {
    padding: 0;
}

.btn-primary {
    --bs-btn-color: #000;
    --bs-btn-bg: #d1608f;
    --bs-btn-border-color: #d1608f;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #d979a1;
    --bs-btn-hover-border-color: #d6729b;
    --bs-btn-focus-shadow-rgb: 82,99,178;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #d980a5;
    --bs-btn-active-border-color: #d6729b;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,.125);
    --bs-btn-disabled-color: #000;
    --bs-btn-disabled-bg: #d1608f;
    --bs-btn-disabled-border-color: #d1608f;
}

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

:root {
    --body-bg: #f7f6f9;
}

.disable-scrollbars() {
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.logo() {
    background-image: url('../svg/logo.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
}

@media (min-width: 992px) {
    .content-top {
        height: 70px;
        padding-left: 20px;

        .content-search .content-search-label::after {
            display: none;
        }

        .form-action-search {
            .form-widget {
                margin: 0 auto;
                max-width: 240px;
                background: #F2F2F7;
                border-radius: 5px;
                padding: 3px 10px;
                display: flex;

                label {
                    display: flex;
                    flex: 1 1 auto;

                    input::after {
                        display: none;
                    }
                }

                input {
                    --content-search-input-bg: #F2F2F7;
                    margin-left: 10px;
                    padding-right: 0;
                    min-width: 0 !important;
                    width: 100%;
                    flex: 1 1 auto;
                }
            }
        }
    }

    #header-logo .logo {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            bottom: -20px;
            width: 100%;
            border-right: 1px solid #E8E7ED;
        }
    }

    .sidebar {
        padding: 0;
    }

    #main-menu {
        border-top: 1px solid #E8E7ED;
    }

    .ea-sidebar-width-normal {
        #main-menu {
            padding: 0 var(--sidebar-padding-right) 0 var(--sidebar-padding-left);
        }
    }

    .ea-sidebar-width-compact #main-menu .menu .menu-item:hover .menu-item-contents {
        background: var(--dondr-primary);
    }

}

.sidebar-wrapper {
    .sidebar {
        background: #fff;
    }

    #header-logo .logo {
        .logo();

        padding: 0;
        margin: 15px 0;
        height: 40px;

        span {
            display: none!important;
        }
    }

    body.ea-sidebar-width-compact .logo {
        width: 25px;
        margin: 0;
    }
}

.content-header {
    padding-top: 20px;
}



#main-menu {
    &> ul.menu {
        &> li.menu-item {
            font-weight: bold;
            margin: 6px 0;
            
            &.active .menu-item-label {
                font-weight: bold;
            }

            a {
                color: var(--dondr-black);
                display: flex;
                align-items: center;

                & > .menu-item-label {
                    flex-grow: 1;
                }
            }

            ul.submenu {
                font-weight: 500;
                li {
                    &::before {
                        content: '';
                        display: block;
                        height: calc(100% + 6px);
                        position: absolute;
                        left: 9px;
                        top: -3px;
                        border-left: 1px solid var(--dondr-primary);
                    }

                    &.active, &:hover {
                        background: none;
                        a {
                            border-radius: 5px;
                            background-color: #F8F7FB;
                            margin-left: 10px;
                            padding-left: 16px;
                            color: var(--dondr-black);
                        }
                    }
                }
            }
        }
    }
}

#main-menu .menu .menu-item .submenu-toggle .submenu-toggle-icon {
    top: 0;
    order: 3;
}

#main-menu .menu .menu-icon {
    transform: translateY(0);
}

.menu-item.has-submenu.expanded .menu-item-contents.submenu-toggle .menu-item-badge {
    display: none;
}


.table {
    --bs-table-bg: transparent;
}

.form-control {
    font-size: inherit;
    font-weight: inherit;
}