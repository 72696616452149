.websites {
    display: flex;
    max-height: calc(100vh - 200px);
    max-width: calc(100vw - 300px);
    // max-width: 1018px;

    overflow-x: hidden;
    overflow-y: auto;
    .disable-scrollbars();

    .websites-side {
        width: 180px;
    }

    .websites-body {
        height: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        border-bottom: 1px solid var(--border-color);
        scroll-snap-type: x mandatory;
        .disable-scrollbars();
    }

    .websites-row {
        display: flex;
        height: 60px;
    }

    .websites-head-row {
        display: flex;
        height: 50px;

        .websites-cell {
            height: 50px;
            font-weight: 600;
            font-size: 14px;
            justify-content: center;
        }
    }

    .websites-cell {
        width: 180px;
        height: 60px;
        background-color: #fff;
        flex-shrink: 0;
        flex-grow: 0;
        border-top: 1px solid var(--border-color);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 15px;
        position: relative;
        font-size: 12px;
        scroll-snap-align: center;

        &:not(:last-of-type){
            border-right: 1px solid var(--border-color);
        }

        &.today {
            background-color: #FFF0F8;
            color: #E5006A;
        
            &::after {
                content: '';
                z-index: 2;
                position: absolute;
                display: block;
                box-shadow: 
                    inset 2px 2px 0 #E5006A,
                    inset -2px -2px 0 #E5006A
                ;
                pointer-events: none;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
            }
        }

        a {
            text-decoration: none;
            color: inherit;
        }

        .websites-cell-menu-button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20px;
            height: 20px;
            border: 1px solid var(--outline-color);
            border-radius: 5px;
        }

        .websites-cell-size {
            font-weight: bold;
            display: flex;

            .websites-cell-warning {
                position: relative;
                margin-right: 5px;

                &.websites-cell-warning-low {
                    color: #e5bf00;
                    span {
                        color: #fff;
                        background-color: #e5bf00;
                    }
                }
                &.websites-cell-warning-mid {
                    color: #e57300;
                    span {
                        color: #fff;
                        background-color: #e57300;
                    }
                }
                &.websites-cell-warning-high {
                    color: #e50000;
                    span {
                        color: #fff;
                        background-color: #e50000;
                    }
                }
                &:not(:hover) {
                    span {
                        display: none;
                    }
                }
                span {
                    white-space: nowrap;
                    position: absolute;
                    left: 0;
                    top: 100%;
                    z-index: 2;
                    padding: 5px 10px;
                    border-radius: 5px;
                }
            }
        }
    }
    
    .websites-head-cell {
        display: flex;
        width: 180px;
        height: 50px;
        background: var(--body-bg);
    }

    .websites-side {
        flex: 0 0 240px;
        height: 100%;
        border-bottom: 1px solid var(--border-color);
        position: relative;

        .websites-head-cell {
            width: 100%;
        }

        .websites-cell {
            width: 100%;
            border-left: 1px solid var(--border-color);
            font-weight: 600;
            font-size: 14px;
            border-right: 1px solid var(--border-color);
        }

        &:first-of-type {
            .websites-head-cell {
                justify-content: right;
            }
        }

        &:last-of-type{
            width: auto;
            min-width: 120px;
            flex-grow: 1;
        }
    }

    .websites-nav {
        display: flex;
        background: #F1F0F2;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 50px;
        text-decoration: none;
        // background-color: var(--lighter-color);
        border: 1px solid var(--border-color);
        border-bottom: 0;
        color: inherit;
        font-size: 12px;
    }

    &:first-child .websites-nav { border-radius: 5px 0 0 0; }
    &:last-child .websites-nav { border-radius: 0 5px 0 0; }
}

.websites-head {
    position: absolute;
    background-color: var(--body-color);
    pointer-events: none;
    z-index: 2;
    .websites-nav {
        pointer-events: all;
    }
    .websites-body {
        scroll-snap-type: none;
    }
}

.websites:not(.websites-head) .websites-head-row {
    opacity: 0;
}

.websites-menu {
    position: absolute;
    padding: 10px;
    border-radius: 5px;
    background-color: #E5006A;
    margin: 0;
    list-style: none;

    &:not(.show),
    li:not(.show){
        display: none;
    }

    a {
        color: #fff;
        text-decoration: none;
        display: flex;
        align-items: center;
        font-size: 12px;
        
        i {
            text-align: center;
            width: 20px;
            margin-right: 5px;
        }
    }
}

.websites-popup {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;

    display: flex;
    align-items: center;
    justify-content: center;

    &:not(.show){
        display: none;
    }

    .websites-popup-inner {
        background-color: #fff;
        padding: 20px;

        display: flex;
        flex-direction: column;


        .websites-popup-row {
            display: flex;
            font-family: monospace;

            .websites-popup-row-date {
                margin-right: 10px;
            }
        }
    }
}