.hours {
    .money-cell {
        width: 150px;
    }

    .add-row :focus {
        position: relative;
        z-index: 100;
    }

    .add-row .actions-cell,
    .add-row .money-cell,
    .add-row .add-row-invoiced-cell {
        padding: .5rem;
        background: #fff;
        margin-bottom: 0;
    }

    .form-check-invoiced {
        padding: 0;
    }

    .hours-type-wrapper {
        display: flex;
    }

    .add-row td {
        padding: 0;
    }
    .add-row .form-control {
        border-radius: 0;
        border: none;
        padding: .5rem;
    }
    input[readonly] {
        color: #999;
    }

    #hours-new-type {
        width: 110px;
    }

    #hours-new-project, #hours-new-customer, #hours-new-centralized-updates {
        width: 190px;
    }

    .filter-form {
        display: flex;
        margin-top: 10px;

    }

    .filter-customer {
        width: 300px;
        margin-right: 10px;
    }
}

.export-button {
    margin-right: 10px;
}

.content-header-title.hours {
    margin-bottom: 15px;
}

.hours-timer-resume {
    display: none;
}

tr:hover .hours-timer-resume {
    display: inline-block;
}